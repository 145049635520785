import APP_CONST from '@/constants/AppConst';
import { programRecruitment } from '@/services/analytics/analyticsService';
import analyticsStore from '@/store/modules/analyticsStore';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Vue, Component } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';

@Component({
    components: {
    }
  })


export default class ScholarAnalyticsComponent extends Vue {
    public scholarNavigationTabs = APP_CONST.ANALYTICS_SCHOLAR_NAVIGATION_TAB;
    public isApiResponse:boolean = false;
    public isMobileView:boolean=false;
    private objScreenText: ScreenText = new ScreenText();
    /* istanbul ignore next */
    get selectedScholarNavigationTab(){
        return analyticsStore.selectedScholarNavigationtabValue;
    }

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    beforeMount(){
        this.getProgramRecruitment()
    }


    async getProgramRecruitment(){
        this.isApiResponse = false;
        let accountId: string | number | null = APP_UTILITIES.getCookie('accountId');
        accountId = accountId ? Number(accountId) : 0
        analyticsStore.mutateDropdownResponseStatus(0)
        let response = await programRecruitment(accountId);
        let dropdownValue = response.data
        let  responseStatus = Number(response.status)
        analyticsStore.mutateDropdownResponseStatus(responseStatus)
        analyticsStore.mutateAnalyticsProgramDropdown(dropdownValue);
        analyticsStore.programNameSelection()
        this.isApiResponse = true;
    }

    setNavigation(url: string, key: string){
        this.$router.push(url);
    }
}